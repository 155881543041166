// Require the polyfill before requiring any other modules (IE11SUX)
require('intersection-observer');

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Auth0 from './auth'
import 'isomorphic-fetch'

Promise.all([
  fetch(process.env.BASE_URL + "homeroom.appSettings.json").then(
    (res) => (res.ok && res.json()) || Promise.reject(res)
  ),
  fetch(process.env.BASE_URL + "messages.json").then(
    (res) => (res.ok && res.json()) || Promise.reject(res)
  ),
]).then((data) => {

  // Attach our environment specifics to Vue
  var appSettings = {};
  var messages = {};
  data.forEach((item) => {
    if (item.appSettings) {
      appSettings = item.appSettings;
    } else if (item.messages) {
      messages = item.messages;
    }
  });

  // Install the authentication plugin here
  Vue.use(Auth0, {
    domain: appSettings.auth.domain,
    clientId: appSettings.auth.clientId,
    audience: 'https://bff.certifid.com',
    onRedirectCallback: appState => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    }
  });

  // What is this?
  Vue.config.productionTip = false;

  // Mixin our appSettings and messages
  Vue.mixin( {
    beforeCreate() {
      const options = this.$options;

      if ( options.appSettings )
        this.$appSettings = options.appSettings;
      else if ( options.parent && options.parent.$appSettings )
        this.$appSettings = options.parent.$appSettings;

      if ( options.messages )
        this.$messages = options.messages;
      else if ( options.parent && options.parent.$messages )
        this.$messages = options.parent.$messages;
    }
  });

  // Now fire Vue up
  new Vue({
    router,
    store,
    appSettings,
    messages,
    render: (h) => h(App),
  }).$mount("#app");

});
