<template>
<div class="ie-blocker has-text-centered">
    <img class="mb-5" src="../assets/logo.svg" alt="CertifID Logo" />
    <div class="inner-card">
        <img src="../assets/img/NotCompatible.svg" alt="Internet Explorer is not compatible with CertifID" />
        <h1 class="is-size-3 my-4 client-title">Security Alert!</h1>
        <div class="blip"></div>
        <p class="mb-5">
            <strong>The browser you are using is unsafe and not-compatible with CertifID's security standards.</strong>
        </p>
        <p class="mb-5">
            Since 1/12/16, Microsoft no longer provides security updates or technical support for your browser.
            <br>
            Visit <a href="https://www.microsoft.com/en-us/microsoft-365/windows/end-of-ie-support">Microsoft's Support Page</a> for more details.
        </p>
        <p class="mb-5">
            To get the most secure experience using CertifID, you'll need to upgrade to one of the browsers below.
        </p>
        <p class="browsers">
            <a href="https://www.microsoft.com/edge" target="_blank" rel="noreferrer"><img src="../assets/img/edge.svg" alt="Download Microsoft Edge"/><br/>
                Microsoft Edge
            </a>
            <a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer"><img src="../assets/img/chrome.svg" alt="Download Google Chrome"/><br/>
                Google Chrome
            </a>
            <a href="https://www.mozilla.org/firefox" target="_blank" rel="noreferrer"><img src="../assets/img/firefox.svg" alt="Download Mozilla Firefox"/><br/>
                Mozilla Firefox
            </a>
            <a href="https://www.apple.com/safari/" target="_blank" rel="noreferrer"><img src="../assets/img/safari.svg" alt="Download Apple Safari"/><br/>
                Apple Safari
            </a>
        </p>
    </div>
</div>
</template>

<script>
//import Vue from 'vue';

export default {

}
</script>

<style lang="scss" scoped>
@import "src/assets/css/certifid.scss";

.ie-blocker {
    padding-top: 100px;

    .inner-card {
        margin: 0 auto;
        width: auto;
        max-width: 750px;
    }

    .browsers {
        padding-top: 15px;
        a {
            display: inline-block;
            font-weight: bold;
            color: $low-emphasis-grey;
            &:hover {
                color: $certifid-blue;
                text-decoration: underline;
            }
        }
        img {
            width: 50px;
            opacity: .3;
            margin: 0 60px;
            margin-bottom: 5ps;
            display: inline-block;
        }
    }

}

</style>
