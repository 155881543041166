<template>
<div>
    <div class="signin-backdrop">
        <sign-in-block :user.sync="user" :parentRememberMe.sync="rememberMe" :loading.sync="performingUserLookup" :lookupFailedMessage="lookupFailedMessage" @perform-user-lookup="performUserLookup"></sign-in-block>
    </div>
    <ie-blocker class="ieblocker"></ie-blocker>
</div>
</template>

<script>
import Vue from "vue";
import SignInBlock from "../components/SignInBlock.vue";
import IEBlocker from "../components/IEBlocker.vue";
import VueCookies from "vue-cookies";
import Axios from "axios";
import Url from "url-parse";

// Setup cookies
Vue.use(VueCookies);

export default {
    data: function () {
        // Return our initial state
        return {
            user: null,
            rememberMe: false,
            performingUserLookup: false,
            lookupFailedMessage: null,
            requestPath: "",
        };
    },
    components: {
        "sign-in-block": SignInBlock,
        "ie-blocker": IEBlocker
    },
    computed: {
        v1Frontend: function () {
            // Reset the URL to the origin so to not confuse the user
            const host = window.location.host.toLowerCase();
            const newHost = host && host.startsWith("home.") ?
                host.substring(5) : host;
            return `//${newHost}`;
        }
    },
    methods: {
        loadUserCookie: function () {
            try {
                return this.$cookies.get(this.$appSettings.priorUserCookie.key);
            } catch (cause) {
                // log the error
                console.error('Failure loading prior user cookie:', cause);
            }
        },
        redirectToV1: function () {
            try {
                // For v1, redirect and let V1's auth handle the rest
                const userEmail = this.user ? this.user.email : null;
                localStorage.setItem(
                    "lastSignedInEmailState",
                    '"' + userEmail + '"'
                );
            } catch (cause) {
                console.error('Failure setting user email for handoff to v1:', cause);
            }

            // Perform the V1 redirect
            const v1Frontend = this.v1Frontend;
            console.log('Redirecting to V1:', v1Frontend);
            window.location.href = v1Frontend;
        },
        signInToV2: function () {
            // For v2, we leverage Auth0
            const userEmail = this.user ? this.user.email : null;
            this.$auth.loginWithRedirect({
                login_hint: userEmail,
            });
        },
        performUserLookup: function (email) {
            // Signal that we're performing the lookup
            this.performingUserLookup = true;

            // Make the call
            var finalVariant = null;
            const self = this;
            Axios.get(`${this.$appSettings.bff.baseUrl}/UserProfile/${email}`, {
                    headers: {
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    }
                })
                .then((response) => {
                    const userProfile = response.data;

                    // If we've got all the pieces we need to move forward, set our cookies ond refresh
                    if (userProfile && userProfile.variant && userProfile.email) {
                        // Work out which variant we're using
                        self.user = userProfile;
                        const userCookie = self.loadUserCookie();
                        const fallbackVariant = userCookie ? userCookie.variant : null;
                        finalVariant = userProfile.variant ? userProfile.variant : fallbackVariant;
                        console.info(`A/B variant values; [user profile] [fallback] [final]: [${userProfile.variant}] [${fallbackVariant}] [${finalVariant}]`);

                        // Set the prior user cookie with the default expiry
                        try {
                            if (self.rememberMe)
                                self.$cookies.set(
                                    this.$appSettings.priorUserCookie.key,
                                    userProfile
                                );
                        } catch (cause) {
                            console.error('Failure saving prior user cookie:', cause);
                        }

                        // Set the A/B variant cookie to expire at the end of the session
                        self.$cookies.set(
                            this.$appSettings.abVariantCookie.key,
                            finalVariant,
                            0, // An expiry of 0 signals a session-based cookie
                            '/',
                            '.certifid.com'
                        );

                        // Temporary logic while V1 is still live (partial Auth0 adoption)
                        if (finalVariant == "v1") {
                            // Redirect to v1
                            self.redirectToV1();
                        } else {
                            // Sign into v2
                            self.signInToV2();
                        }
                    } else {
                        // Something's not quite right...
                        self.lookupFailedMessage = this.$messages.defaultError;
                        console.error(
                            `messages.incompleteUserProfile: this.$messages.incompleteUserProfile`
                        );
                    }
                })
                .catch((error) => {
                    // Report an error
                    self.lookupFailedMessage = this.user ?
                        this.$messages.userLookupPrior :
                        this.$messages.userLookupEmail;

                    // Log the error
                    console.error(error);

                    // Signal that we're done with the lookup
                    self.performingUserLookup = false;
                });
        },
        autoUserLookup: function () {
            if (this.$auth.user && this.$auth.user.email) {
                if (this.$appSettings.autoUserLookup) {
                    this.performUserLookup(this.$auth.user.email);
                } else {
                    console.warn(
                        'User is authenticated, but automatic user lookup is disabled; this is intended for local development to prevent an infinite loop.'
                    );
                    this.performingUserLookup = false;
                }
            }
        },
    },
    watch: {
        user: function (newUser) {
            if (!newUser) {
                // We've signaled that we're clearing the user, delete the cookie
                this.$cookies.remove(this.$appSettings.priorUserCookie.key);
            }

            // The user has changed - clear any loading errors
            this.lookupFailedMessage = null;
        },
        "$auth.loading": function (isLoading) {
            // If we're loading, there's nothing to do but wait
            if (isLoading) return;

            // We're not authenticated, proceed
            this.performingUserLookup = false;
        },
    },
    mounted: function () {
        // Reset the URL to the origin so to not confuse the user
        this.requestPath = window.location.href;
        const url = new Url(this.requestPath);
        window.history.replaceState({}, document.title, url.origin);

        // Setup default values
        this.rememberMe = this.$appSettings.rememberMeDefault;

        // Setup cookies
        Vue.$cookies.config(
            this.$appSettings.priorUserCookie.expiry,
            "/", // Path
            "", // Domain
            this.$appSettings.priorUserCookie.httpsOnly, // Secure
            "Lax" // SameSite
        );

        // Grab the prior user
        this.user = this.loadUserCookie();
    },
};
</script>

<style lang="scss" scoped>
.signin-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d3450;
    background-image: url(../assets/wave-white.png);
    background-repeat: repeat;
    width: 100%;
    height: 100%;
    min-height: calc(100vh);
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
   /* IE10+ specific styles go here */ 
   .signin-backdrop {
        align-items: baseline;
        justify-content: flex-start;
   }
}

</style>
