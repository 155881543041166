<template>
  <!--  Possible Events / arguments -->
  <!--
        sign-in-user  -  loginInfo {username}
        reset-sign-in -  user clicked "Use a different account"

    -->
  <!-- End Possible Events -->

  <div class="signin-container">
    <div class="signin-block">
      <img class="mt-5" src="../assets/logo-icon.svg" />
      <span class="signin-title is-size-3 my-4">Sign In</span>
      <div class="blip"></div>

      <div class="field-container">
        <!-- User login -->
        <div>
          <b-loading :active="loading" :is-full-page="false" />
          <div v-if="!user">
            <b-field
              label="Email"
              :message="lookupFailedMessage"
              :type="emailFieldType"
            >
              <b-input
                ref="email"
                type="email"
                v-model="email"
                class="mb-5"
                required
                dautocomplete="off"
                v-observe-visibility="emailVisibilityChanged"
                :disabled="loading"
                @keyup.enter.native="performUserLookup"
              ></b-input>
            </b-field>
            <div role="button" class="mb-5">
              <b-button
                expanded
                type="is-primary"
                class="my-3"
                :disabled="!isValid || loading"
                @click="performUserLookup"
                >Continue</b-button
              >
            </div>
            <b-field>
              <center>
                <b-checkbox v-model="rememberMe" :disabled="loading"
                  >Remember Me</b-checkbox
                >
              </center>
            </b-field>
          </div>
          <div v-else>
            <div class="user-container" @click="performUserLookup">
              <div class="left">
                <c-avatar
                  :user="user"
                  :avatarUrl="user.avatarUrl"
                  size="45px"
                  background-color="#EEE"
                  text-color="#162F4D"
                  font-size="1.20rem"
                ></c-avatar>
              </div>
              <div class="right">
                <span class="user-name">{{ user.name }}</span>
                <span class="user-subname">{{ user.subName }}</span>
              </div>
            </div>
            <p class="help is-danger">{{ lookupFailedMessage }}</p>

            <a class="different-account-link" @click="clearUser">
              Use a different account
            </a>
          </div>
        </div>
      </div>
    </div>
    <span>Wire Fraud Stops Here.</span>
  </div>
</template>
<script>
import Vue from "vue";
import Input from "buefy";
import Avatar from "./Avatar.vue";
import VueObserveVisibility from "vue-observe-visibility";

Vue.use(Input);
Vue.use(VueObserveVisibility);

export default {
  props: ["user", "parentRememberMe", "loading", "lookupFailedMessage"],
  data: function() {
    return {
      email: this.user ? this.user.email : "",
      isValid: false,
      rememberMe: this.parentRememberMe,
    };
  },
  components: {
    "c-avatar": Avatar,
  },
  methods: {
    clearUser: function() {
      // Tell the parent components to set the user to null
      this.$emit("update:user", null);
    },
    performUserLookup: function() {
      // If we're currently loading - do nothing
      if (this.loading) return;

      // Tell the parent components that we're ready to lookup the user
      this.$emit("perform-user-lookup", this.email);
    },
    emailVisibilityChanged: function(isVisible) {
      // When the email field becomes visible, and is empty, assign focus to it
      if (isVisible && !this.email) this.$refs.email.focus();
    },
  },
  watch: {
    user: function(newUser) {
      this.email = newUser ? newUser.email : "";
    },
    parentRememberMe: function(newParentRememberMe) {
      this.rememberMe = newParentRememberMe;
    },
    rememberMe: function(newRememberMe) {
      // Signal update to the remember me field
      this.$emit("update:parentRememberMe", newRememberMe);
    },
    email: function() {
      // Drop back to HTML5 validation checks
      //  This way we don't have to manage clumsy validation on our own,
      //  and this call doesn't trigger the validation message while typing, which is a plus...
      const email = this.$refs.email;
      const el = email && email.getElement();
      this.isValid = el && el.checkValidity();
    },
  },
  computed: {
    emailFieldType: function() {
      const email = this.$refs.email;
      return this.lookupFailedMessage || (email && email.validationMessage)
        ? "is-danger"
        : null;
    },
  },
  mounted: function() {},
};
</script>
<style lang="scss" scoped>
.verify-links {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 80%;

  a {
    font-size: 0.8rem;
    color: #2e76b5;
    flex-grow: 1;
    flex-basis: 0px;
    text-decoration: underline;
    margin-top: 2rem;
  }
}

.help-block {
  max-width: 167px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.security-icon {
  color: #2e76b5;
  font-size: 1.4rem;
}

.security-btn {
  height: 80px;
}

.signin-container {
  position: absolute;
  z-index: 1;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > span {
    color: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.13);
    margin-top: 1rem;
  }
}

.blip {
  width: 25px;
  border-bottom: 1px solid #2e76b5;
  height: 1px;
  margin-bottom: 1rem;
}

.signin-block {
  width: 100%;
  max-width: 357px;
  //height: 100%;
  //max-height: 462px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.13);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../assets/sign-in-wave.svg);
  background-repeat: repeat-x;
  background-position: bottom;
  padding-bottom: 4rem;

  .field-container {
    width: 80%;
  }

  .user-container {
    width: 100%;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 14px;
    display: flex;
    font-size: 0.9rem;
    background-color: #fff;
    transition: background-color 0.2s;
    cursor: pointer;

    .left {
      flex-grow: 0;
    }

    .right {
      flex-grow: 1;
      padding-left: 16px;
      padding-top: 2px;

      span {
        display: block;
      }
    }

    .user-subname {
      font-size: 0.8rem;
    }

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .different-account-link {
    display: block;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    text-decoration: underline;
    text-align: center;
    color: #a0a2a4;
    font-size: 0.8rem;
  }
}

.signin-title {
  font-family: "DM Serif Display", serif;
}
</style>
