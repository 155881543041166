<template>
  <div v-if="user">
    <div
      v-if="!user.avatarUrl || imageFailed"
      class="account-avatar"
      :style="customStyle"
    >
      {{ initials }}
    </div>
    <div v-else class="account-avatar" :style="customStyle">
      <b-image
        :src="user.avatarUrl"
        :alt="user.name"
        :rounded="true"
        @error="imageFailed = true"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: ["user", "backgroundColor", "textColor", "size", "fontSize"],
  data: function() {
    return {
      imageFailed: false,
    };
  },
  watch: {
    user: function(newUser, oldUser) {
      if (!newUser || !oldUser || newUser.avatarUrl != oldUser.avatarUrl)
        this.imageFailed = false;
    },
  },
  computed: {
    initials: function() {
      const user = this.user;
      const name = user ? user.name : null;
      if (!name) return;
      var names = name.split(" "),
        initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
    customStyle: function() {
      var ret = {};

      if (this.backgroundColor) ret["background-color"] = this.backgroundColor;
      if (this.textColor) ret["color"] = this.textColor;
      if (this.size) {
        ret["width"] = this.size;
        ret["height"] = this.size;
      }
      if (this.fontSize) {
        ret["font-size"] = this.fontSize;
      }

      return ret;
    },
  },
};
</script>
<style lang="scss" scoped>
.account-avatar {
  width: 31px;
  height: 31px;
  border-radius: 999px;
  background: #162f4d;
  color: #fff;
  text-align: center;
  line-height: 31px;
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
